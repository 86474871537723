/* Common Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.btn {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

/* Success Page Styles */
.success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #d4edda;
}

.success-page h1 {
  color: #155724;
}

.success-page p {
  color: #155724;
}

/* Failure Page Styles */
.failure-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8d7da;
}

.failure-page h1 {
  color: #721c24;
}

.failure-page p {
  color: #721c24;
}

/* Responsive Styles */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 0.9rem;
  }

  .btn {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
