/* About Scetio */
.about_section {
    background-image: url("../../public/images/maharashtra.gif");
    position: relative;
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
}

.about_section p {
    font-size: 13px;
    color: #fff;
    margin-top: 20px;
    text-align: justify;
}

.about_section h3 {

    color: #fff;
    margin-top: 20px;
}

.about_section h4 {
    color: #fff;
    font-size: 20px;
}

.layer {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.about_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_img img {
    margin-top: 40px;
    border-radius: 5px;
    box-shadow: 10px 10px;

}


/* Footer Section (pubilc section) */
footer {
    margin-top: 10px;
    background: #0d0401;
    color: #bbb;
    padding: 15px 0 55px 0;

}

footer .widgetheading {
    position: relative;


}

.widgetheading {
    width: 100%;
    padding: 0;
    font-size: 16px;
    color: #fff !important;
    margin-bottom: 10px;
    margin-top: 10px;

}

ul.link-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 10px;
    display: flex;
}

ul.link-list li {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 13px;
    padding: 2px 0px 3px 0px;
    background-color: #fff;
    list-style: none;
    margin-left: 4px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icred {
    font-size: 14px;
}

footer ul.link-list li a {
    color: #666;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}


ul.link-list li:hover .fa {
    color: #fff;
}

ul.link-list li:hover {
    background-color: #4b134f;
}

.contact_detail {
    list-style: none;
    padding-left: 0px;
}

.contact_detail li {

    margin-left: 0 !important;
}

.formgroup input {
    /* width: 230px; */
    height: 35px;
    align-items: center;
    border-radius: 5px;
    align-items: flex-start;
    ;
}

.formgroup ::placeholder {

    text-align: left;
    padding: 10px;
}

.formgroup textarea {

    /* width: 550px; */
    height: 80px;
    align-items: center;
    border-radius: 5px;
}

.submit_btn {
    padding: 2px 10px;
    background-color: #4b134f;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #4b134f;
}

.btn_footer {

    text-align: center;
}

footer span {
    color: #fff;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    /* margin-left: 32rem; */
    /* margin-bottom: 20px; */
}

footer span a {
    text-decoration: none;
}

footer a {
    color: #eee;
}

footer a :hover {
    color: #eee;
}

.websitepolicy {
    text-align: center;
}

.websitepolicy span {
    color: #fff;
    font-size: 12px;
}

/* Navbar Section */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style: none;
    display: inline-block;
    /* margin-left: 2.5rem; */

}

/* .navbar li {
    margin: 0 25px;
} */

.main_navigation {
    background-image: radial-gradient(#ad5389, #3c1053);
    z-index: 10000;
    height: 2.5rem;
    /* padding: 1rem; */
    /* padding-left: 4.5rem; */

}

.navbar-expand-lg .navbar-collapse {
    width: 100%;
}

.navbar-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0px;
    overflow-y: scroll;
    height: 450px;
}

.dropdown1:hover .dropdown-menu {
    display: block;
    /* overflow-y: scroll; */
    height: auto;
    max-height: 450px;
    width: 320px;
}

.dropdown-item1 {
    text-align: center;
    color: #000;
    width: 350px;
    box-shadow: 2px 6px 5px -1px rgb(224 224 224 / 75%);
    text-transform: capitalize;
    /* border-bottom: 1px solid #f2f2f2;
     border-right: 1px solid #f2f2f2; */
    /* margin: 10px; */
    margin-left: 1px;
    /* white-space: normal !important; */
}

.dropdown-item {
    text-align: center;
    width: 350px;
    box-shadow: 2px 6px 5px -1px rgb(224 224 224 / 75%);
    text-transform: capitalize;
    border-bottom: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    /* margin: 10px; */
    margin-left: 1px;
    white-space: normal !important;
}

.dropdown_text {
    font-size: 12px;
    text-align: left;
}

.dropdown-item {
    margin: 0;
    margin-left: 1px;

}

.dropdown-item ul {

    /* width: 450px; */
    max-height: 400px;
    background-color: #fff;
    text-align: justify;
    padding: 0px 6px;

}

.dropdown_application_guideline {

    height: auto;
    min-height: 450px;
}

.navbar li span {
    color: #000 ;
    margin: 20px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 7px;
    text-align: center;
    justify-content: center;
    padding-top: 10px;

}

.top_header {
    height: 40px;
    overflow: hidden;
}

.top_header li {
    width: 150px;
    display: grid;
    place-items: center;
    margin-right: 5px;
}

#google_translate_element {
    margin-top: 15px;
}

.goog-te-combo {
    padding: 5px;
    width: 150px;
}

.goog-logo-link {
    display: none;
}

.note_scroll {
    overflow-y: scroll;
    height: 60vh;
    padding: 10px;
}

.note_scroll::-webkit-scrollbar {
    width: 2px;
}

.note_scroll::-webkit-scrollbar-thumb {
    background-color: #666;
    outline: 1px solid #eee;
}

.gallery {
    margin: 20px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 7px;
    text-align: center;

}

.gallery_grid {
    border: 8px solid lightgray;
    display: flex;
    padding: 1px 1px 1px 1px;
   
    width: 108PX;
    height: 89px;


}

/* .gallery_grid img {
    width: 100%;
} */

.note_pointer {

    cursor: pointer;
}

/* Other Slider  */
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel .item {
    color: #333;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}

.carousel .img-box {
    width: 145px;
    height: 145px;
    float: left;
    margin: 0 auto;
    border-radius: 50%;
}

.carousel .img-box img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
    shape-outside: circle(50%);
}

.carousel .carousel-control {
    width: 30px;
    height: 30px;
    background: #4b134f;
    text-shadow: none;
    position: absolute;
    cursor: pointer;
    top: -40px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(130, 130, 130, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(130, 130, 130, 1);
    box-shadow: 0px 0px 5px 0px rgba(130, 130, 130, 1);
}

.carousel-control i {
    font-size: 20px;
    margin-right: 2px;
}

.carousel-control.left {
    left: auto;
    right: 40px;
}

.carousel-control.right i {
    margin-right: -2px;
}

.other_links ul li a {
    display: block;
    color: black;
}

.link {
    background-color: #d47ae83b;
    width: 25%;
    display: inline-block;
    font-size: 12px;
    margin: 2px;
    min-height: 30px;
    line-height: 30px;
    padding-left: 10px;
    font-weight: 500;
    text-align: center;


}

.link li {
    margin-left: 0 !important;
}

.other_links h3 {
    color: #3c1053;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
}

.success_stories {
    margin-top: 10px;
}

/* WebsitePolicy */

.container_websitepolicy {

    margin-top: 20px;
    text-align: justify;
    margin-bottom: 25px;

}

.container_websitepolicy p {

    font-size: small;
}

.websitepolicy span {

    margin-top: 0;

}
/* 
.notification{
    position: sticky;
    top: 0;
} */

.public_notification_main .table_heading h5 {
    font-size: 15px !important;
}
.public_notification_main  .table_data h4{
    font: size 12px !important; ;
}

#storySlider {
    position: relative;
}

#storySlider .indicators {
    position: absolute;
    top: 8%;
    right: 0;
    width: 80px;
}

#storySlider .carousel-control-next,
#storySlider .carousel-control-prev {
    width: 50%;
}

#storySlider .carousel-control-next {
    position: absolute;
    top: 0;
    right: 0;
}

#storySlider .carousel-control-next-icon,
#storySlider .carousel-control-prev-icon {
    background-color: var(--buttonBgColor);
    width: 1.5rem;
    height: 1.5rem;
}

#storySlider .carousel-inner h6 {
    line-height: 20px !important;
}

#storySlider .carousel-indicators {
    position: absolute;
    top: 95%;
}
/* 
.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 8.5rem !important;
    padding-left: 0.5rem;
} */

/* media quries for medai screen 320px to 375px */
@media screen and (width: 375px) {

    .footer_common {
        font-size: 10px;
    }

    footer .widgetheading {
        position: relative;
        font-size: 12px;

    }

    /* .contact_detail li{
        font-size: 10px;
    } */


    footer .widget .widgetheading p {
        font-size: 12px;
    }

    .widget p {
        font-size: 10px
    }

    /* .note_scroll{
    overflow-y:none;
    height: 0;
    } */

    /* .other_links{
    display: none;
    } */


    .link {
        background-color: #d47ae83b;
        /* width: calc(84%/4);
    display: inline-block; */
        font-size: 12px;
        margin: 2px;
        min-height: 30px;
        /* line-height: 30px; */
        padding-left: 10px;
        font-weight: 500;
        text-align: center;
        /* float: left;*/
    }

}

@media screen and (width: 320px) {

    .footer_common {
        font-size: 10px;
    }

    footer .widgetheading {
        position: relative;
        font-size: 12px;
    }

    /* .contact_detail li{
        font-size: 10px;
    } */


    footer .widget .widgetheading p {
        font-size: 12px;
    }

    .widget p {
        font-size: 10px
    }

    /* 
    .note_scroll{
    overflow-y:none;
    height: 0;
    } */

    /* .other_links{
    display: none;
    } */


    /* .link {
        background-color: #d47ae83b;
        width: calc(84%/4);
    display: inline-block;
        font-size: 12px;
        margin: 2px;
        min-height: 30px;
        line-height: 30px;
        padding-left: 10px;
        font-weight: 500;
        text-align: center;
        float: left;
    } */

}

@media screen and (max-width: 320px)and (min-width:425px)
 {

    
    .font_class .H4{
        font-size: 16px !important;
    }
    .navbar-expand-md  .nav-link
.navbar-nav .nav-item .dropdown  .nav-link .dropdown-toggle
     {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    
    .navbar-nav {
        margin: 0.5px 10px !important;
    
    }
.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    /* -webkit-box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%); */
    /* box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%); */
    -webkit-overflow-scrolling: touch;
}
   
    .navbar li span {
        color: #000 !important;
        margin: -10px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        margin-left: 7px;
        text-align: center;
        justify-content: center;
        padding-top: 10px;
        width: 260px !important;
        padding-right: 2.5rem !important;
    
    }
    
    .navbar-dark .navbar-toggler {
        color: #3c1053;
        border-color: rgba(255, 255, 255, .1);
        position: relative;
        margin-left: 10px;
    }
/* 
    .bg-dark_nav {
        background-color: #3c1053!important;
    } */

    .navbar {
        background: #3c1053 !important;


    }

    .footer_common {
        font-size: 10px;
    }

    footer .widgetheading {
        position: relative;
        font-size: 12px;

    }

    .contact_detail li {
        font-size: 10px;
    }


    footer .widget .widgetheading p {
        font-size: 12px;
    }

    .widget p {
        font-size: 10px
    }




    .link {
        background-color: #d47ae83b;
        /* width: calc(84%/4);
    display: inline-block; */
        font-size: 12px;
        margin: 2px;
        min-height: 30px;
        /* line-height: 30px; */
        padding-left: 10px;
        font-weight: 500;
        text-align: center;
        /* float: left;*/
    }

    .mobile-container {
        max-width: 480px;
        margin: auto;
        background-color: #555;
        height: 500px;
        color: white;
        border-radius: 10px;
    }

    .topnav {
        overflow: hidden;
        background-color: #333;
        position: relative;
    }

    .topnav #myLinks {
        display: none;
    }

    .topnav a {
        color: white;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
        display: block;
    }

    .topnav a.icon {
        background: black;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav a:hover {
        background-color: #ddd;
        color: black;
    }

    .active {
        background-color: #04AA6D;
        color: white;
    }

}

.navbar-dark .navbar-toggler-icon {
    font-size: 16px;
    margin-top: -2px;
    float: left;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .55);
    /* border-color: rgba(255,255,255,.1); */
    position: absolute;
    top: 0;
    margin-top: 10px;
    margin-left: 5px;
}



@media only screen and (min-width:375px)  {
    .mq_our_gallery{
        margin-left: 40px !important;
        display: grid;
        place-items: center;
    }


}

@media only screen and (min-width:320px){
    .mq_our_gallery{
        margin-left: 7px;
        display: grid;
        place-items: center;
    }

}
@media only screen and (min-width:425px)  {
    .mq_our_gallery{
        margin-left: 57px !important;
        display: grid;
        place-items: center;
    }

}
@media only screen and (min-width:320px) and (max-width:425px) {

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem ;
        padding-left: 0.5rem;
    }

    .col-md-6{
        position: none !important;
    }

    .navbar {
        position: sticky !important;
        top: 0% !important;
        z-index: 20;
        min-height: 50px;
        margin-bottom: 20px;
        border: 1px solid transparent;
    }
    
        .navbar-collapse {
            margin-top: 35px;
            padding-right: 15px;
            padding-left: 35px ;
            overflow-x: visible;
           
    }
    /* .carousel .slide  .carousel-indicators{
        z-index:99 ;
    } */

    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0px;
        overflow-y: scroll;
        height: 450px;
        width: 300px !important;
    }

    .navbar li span {
        color: #000;
        margin: 20px;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-left: 7px;
        text-align: center;
        justify-content: center;
        padding-top: 10px;
        width: 250px !important;
    }

    .navbar-nav {
        width: 100%;
        display: flex;
    }

    .navbar-nav>li>a {
        padding-top: 33px !important;
        padding-bottom: 10px !important;
        line-height: 20px !important;
        transition: color .15s ease-in-out;
}
   
   
   .carousel-indicators .active {
            width: 5px !important;
            height: 3px !important;
            margin: 0;
    }

    .main_header {
        display: flex;
    }

    .main_header_left img {
        width: 50px;
        align-items: left !important;
  
    }

    .main_header_right img {
        width: 85%;
        height: 85%;
        display: none;
    }

 
    .main_header_middle h3 {
        font-size: 12px !important;
        margin-top: 0px;
    }

    .main_header_middle h4 {
        font-size: 10px !important;
    }

    .public_notification_main .table_heading  H5{
        font-size: 10px !important;

    }
    .public_notification_main  .table_data  H6{
        font-size: 12px !important; ;
    }
    .public_notification_main H4{
        font-size:16px !important ;
    }
    H4{
        font-size: 16px !important;
    }
    .note_scroll {
        height: 190px;
    }

    .footer h6 {
        font-size: 10px !important;

    }

    .success_stories {
        display: none;
    }

    .about_img img {
        display: none;
    }

    .gallery_grid {
        border: 8px solid lightgray;
        display: flex;
        padding: 1px;
        padding-bottom: 4px;
        width: 120PX;
        height: 115px;
        margin-top: 3px;
    }

    .gallery_grid img {
        width: 100%;
        height: 100%;
    }

    .btn_footer {
        margin-bottom: 20px;
    }

    .other_links h4 {
        margin-top: 0px;
    }

    /* .other_links_col_media   {
                width:100%  ;
                font-size: 10px;
            } */

    .other_links_media {
        /* display: flex; */
       /* justify-content: center; */
        background-color: #d47ae83b;
        /* padding: 8px; */
        font-size: 10px;
        margin: 1px;
        font-weight: 600;
    }

 

.table_heading,
.table_data,
.table_content {
    border: 1px solid #eab7f5;
    padding: 7px 8px;
    text-align: center;
    border-radius: 5px;
    position: relative;
}

.login_form 
 {
    width: 100%;
    margin: 8px;
    background-color: var(--whiteThemeBgColor);
    border-radius: 0.5rem;
     padding: 5%;
     margin-bottom: 100px;
}
.login_links H6{
    font-size: 11px !important;
    font-weight:bold ;
}
.login_section  {
    width: 100%;
    margin-bottom: 70px;
}

.top_header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    font-size: 11px;
    padding: 5px 0%;
    background-color: var(--whiteThemeBgColor);
}

.submit_btn {
    padding: 2px 5px;
    background-color: #4b134f;
    color: #fff;
    margin: 0px 3px 0px 0px;
    font-size: 8px;
    border-radius: 10px;
    border: 1px solid #4b134f;
}

.about_section p {
    font-size:12px;
    color: #fff;
    margin-top: 4px;
    text-align: justify;
}
.registration_form {
    height:50vh;
    width: 100%;
    overflow-y: auto;
    padding: 1% 5%; 
    /* margin: 5px; */
}
.registration_form input {
font-size:10px;
width: 90%;
}

.registration_form textarea {
   
    width: 90%;
    }
input::-webkit-input-placeholder {

    font-size:10px;
}

input::-webkit-textarea-placeholder {

    font-size:10px;
}

textarea::placeholder { /* Modern browsers */
    font-size: 11px; 
  }

}

@media  screen and (min-width:786px) {

    .other_links_media {
         display: flex; 
      justify-content: center;
        background-color: #d47ae83b;
        font-size: 12px;
        margin: 2px;
        font-weight: 500;
    
    } 
    
}
@media (min-width: 768px){
.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 8.5rem;
    padding-left: 0.5rem;
    }
}

@media screen and (min-width:1024px) and (max-width:768px){
    .other_links_media {
        /* display: flex; */
       /* justify-content: center; */
        background-color: #d47ae83b;
        font-size: 12px;
        /* margin: 2px; */
        font-weight: 500;
    }
    /* .main_header {
        display: flex;
    }

    .main_header_left img {
        width: 50px;
        align-items: left !important;
        display:none
    }

    .main_header_right img {
        width: 85%;
        height: 85%;
    } */

    /* .right_box h3{
        font-size: 10px;
    } */
    /* .main_header_middle h3 {
        font-size: 12px !important;
    }

    .main_header_middle h4 {
        font-size: 10px !important;
    }

    .public_notification_main .table_heading h5 {
        font-size: 10px !important;

    }

    .note_scroll {
        height: 190px;
    }

    .footer h6 {
        font-size: 10px !important;

    }

    .success_stories {
        display: none;
    }

    .about_img img {
        display: none;
    }

    .gallery_grid {
        border: 8px solid lightgray;
        display: flex;
        padding: 1px;
        padding-bottom: 4px;
        width: 120PX;
        height: 115px;
        margin-top: 3px;
    }

    .gallery_grid img {
        width: 100%;
        height: 100%;
    }

    .btn_footer {
        margin-bottom: 20px;
    }

    .other_links h4 {
        margin-top: 0px;
    } */

    /* .other_links_col_media   {
                width:100%  ;
                font-size: 10px;
            } */

 

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 8.5rem !important;
        padding-left: 0.5rem;
    }

.table_heading,
.table_data,
.table_content {
    border: 1px solid #eab7f5;
    padding: 7px 8px;
    text-align: center;
    border-radius: 5px;
    position: relative;
}
.login_section
.login_form 
 {
    width: 100%;
    margin: 8px;
    background-color: var(--whiteThemeBgColor);
    border-radius: 0.5rem;
    padding: 5%;
    margin-bottom: 100px;
}
.login_links H6{
    font-size: 11px !important;
    font-weight:bold ;
}
.login_section  {
    width: 100%;
    margin-bottom: 70px;
}

.top_header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    font-size: 14px;
    padding: 5px 10%;
    background-color: var(--whiteThemeBgColor);
}

.submit_btn {
    padding: 2px 5px;
    background-color: #4b134f;
    color: #fff;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid #4b134f;
}


.registration_form {
    height: 50vh;
    width:  100%;
    overflow-y: auto;
    padding: 1% 5%; 
    /* margin: 5px; */
}
.registration_form input {
font-size:10px;
width: 100%;
}

.registration_form textarea {
    width: 100%;
    }
input::-webkit-input-placeholder {

    font-size:10px;
}

input::-webkit-textarea-placeholder {

    font-size:10px;
}

textarea::placeholder { /* Modern browsers */
    font-size: 11px; 
  }

}

#mainSlider {
    width: 100%;
}



.other_links_col {
    width: 24%;
}


