@media only screen and (min-width:1024px) and (max-width:1270px) {

    .myapplication_main .table_heading,
    .myapplication_main .table_data {
        width: 78%;
    }

    .acknowledgement_form {
        width: 35%;
    }
}

@media only screen and (min-width:1376px) and (max-width:1680px) {
    .back_office_table {
        width: 1500px;
    }
}

@media only screen and (min-width:1681px) and (max-width:2200px) {
    .back_office_table {
        width: 1850px;
    }
}


@media only screen and (min-width:1024px) and (max-width:1340px) {
    .back_office_table {
        width: 1200px;
    }

    .add_review_form {
        width: 50%;
    }

    .back_office_button_group button {
        font-size: 12px;
    }

    .back_office_navbar li {
        margin: 0 23px;
    }

    .back_office_navbar li span {
        font-size: 14px;
    }
}

/*----Admin media query--*/
@media only screen and (min-width:1024px) and (max-width:1250px) {
    .side_nav {
        width: 22%;
    }

    .dashboard_view {
        width: 78%;
    }

    .tab_list li {
        padding: 7px;
    }

    .tab_list li .fa,
    .tab_list li h6 {
        font-size: 13px !important;
    }

    /* .form {
        width: 50%;
    } */

    .login_form, .grievance_form, .acknowledgement_form, .add_review_form, .add_remittance_form{
        width: 50%;
        margin: 20px;
        background-color: var(--whiteThemeBgColor);
        border-radius: 0.5rem;
        padding: 2%;
    }

}

    