@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins', sans-serif;
    --whiteThemeBgColor: #d47ae83b;
    --darkThemeBgColor: #3c3c3c;
    --activeTabBgColor: #790a81;
    --buttonBgColor: #790a81;
    --boxShadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: rgb(173, 83, 137);
    border-radius: 4px;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

h3,
h4,
h5,
h6,
label {
    font-family: 'poppins', sans-serif;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

label {
    font-weight: 400;
}

input,
select,
textarea {
    font-size: 15px !important;
}

input[type="text"]:disabled,
input[type="number"]:disabled,
input[type="date"]:disabled,
input[type="file"]:disabled,
textarea:disabled,
select:disabled {
    background-color: #fff !important;
}

/*-----validation error-----*/
.srv-validation-message {
    color: rgb(173, 9, 9);
    margin-top: 6px;
    text-align: left;
    font-size: 15px;
}

.document_attachement_note {
    font-size: 15px;
    color: var(--activeTabBgColor);
}

.data_not_available_message {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 25px; */
}

.data_not_available_message span {
    font-weight: 500;
}

.update_statistic_select {
    height: 25px;
    padding: 0.1rem 2.25rem 0.1rem 0.75rem;
}

/*--------css for loader start--------*/
.loader_main {
    width: 80%;
    /* width: 100%; */
    height: 75vh;
    position: absolute;
    background-color: transparent;
    overflow: hidden;
}

.common_loader_main {
    width: 100%;
    height: 70vh;
    position: relative;
    background-color: transparent;
    overflow: hidden;
}

.loader {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #fff; */
    margin: auto;
    border: 5px solid #45474b;
    border-top-color: #1877f2;
    border-radius: 50%;
    animation: load 0.6s linear infinite;
}

@keyframes load {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/*--------css for loader end--------*/
.top_header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    padding: 5px 10%;
    background-color: var(--whiteThemeBgColor);
}

.top_header span {
    margin: 0 5px;
    cursor: pointer;
}

.login_detail_box,
.back_office_logout_box {
    position: absolute;
    top: 6%;
    right: 0;
    width: 280px;
    height: 130px;
    border-radius: 0.25rem;
    box-shadow: var(--box_shadow);
    background-color: #fff;
    padding: 15px;
    border: 1px solid var(--whiteThemeBgColor);
    z-index: 2;
}

.back_office_logout_box {
    height: 150px;
}

.login_detail_box .close_btn,
.back_office_logout_box .close_btn {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
    cursor: pointer;
    z-index: 1;
    color: #000;
}

.login_detail_box .user_info,
.back_office_logout_box .user_info {
    margin: 15px 0px;
}

.login_detail_box .userName,
.back_office_logout_box .userName {
    margin-bottom: 10px;
}

.back_office_logout_box .user_type {
    margin-top: 10px;
}

.whiteTheme,
.darkTheme {
    padding: 3px 5px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    margin: 0 5px;
}

.darkTheme {
    background-color: var(--darkThemeBgColor);
    color: #fff;
}

.user_icon {
    width: 25px;
    height: 25px;
    background-color: var(--darkThemeBgColor);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.user_icon i {
    color: #fff;
    cursor: pointer;
    font-size: 13px;
}

.main_header {
    line-height: 40px;
    overflow: hidden;
    border: 1px solid var(--whiteThemeBgColor);
}

.main_header_left,
.main_header_right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_header_middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_header_left img {
    width: 100px;
}

.main_header_right img {
    width: 100%;
    height: 100%;
}

.footer {
    width: 100%;
    padding: 2px 0;
    position: fixed;
    bottom: 0;
}

/*-----css for Admin portal----*/
.login_section,
.grievance_form_section,
.acknowledgement_form_section,
.add_review_form_section,
.remittance_form_section {
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grievance_form_section,
.acknowledgement_form_section,
.add_review_form_section {
    height: 73vh;
}

.remittance_form_section,
.add_review_form_section {
    height: 73vh;
}

.login_form,
.grievance_form,
.acknowledgement_form,
.add_review_form,
.add_remittance_form {
    width: 30%;
    margin: auto;
    background-color: var(--whiteThemeBgColor);
    border-radius: 0.5rem;
    padding: 2%;
}

.grievance_form {
    width: 35%;
}

.add_review_form {
    width: 43%;
}

.add_remittance_form {
    width: 48%;
}

.admin_dashboard {
    width: 100%;
    height: 75vh;
    display: flex;
    overflow: hidden;
}

.side_nav {
    width: 20%;
    height: 75vh;
    overflow: auto;
    padding: 5px;
    /* box-shadow: var(--boxShadow); */
}

.dashboard_view {
    width: 80%;
    height: 75vh;
    overflow: hidden;
    box-shadow: var(--boxShadow);
}

.tab_list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.tab_list li {
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    padding: 16px;
    box-shadow: var(--boxShadow);
    margin-bottom: 18px;
}

.tab_list li:hover .fa,
.tab_list li:hover h6 {
    color: #790a81 !important;
}

.tab_list li .fa,
.tab_list li h6 {
    color: #000;
}

.tab_list a {
    text-decoration: none;
}

.tab_list .active li {
    background-color: var(--activeTabBgColor);
}

.tab_list .active li .fa,
.tab_list .active li h6 {
    color: #fff !important;
}

.tab_content_main {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow-y: auto;
    padding: 0 15px;
}

.button_group,
.raise_grievance_btn,
.second_installment_button {
    text-align: end;
    margin-bottom: 10px;
}
.raise_grievance_btn {
    text-align: start;
    margin-bottom: 0;
    margin-top: 5px;
}
.preview_button {
    text-align: start;
}

.button_group button,
.raise_grievance_btn button,
.second_installment_button button,
.preview_button button,
.raise_second_installment button {
    width: 100px;
    background-color: var(--buttonBgColor);
    border-radius: 4px;
    border: 1px solid var(--buttonBgColor);
    color: #fff;
    padding: 2px 6px;
    font-size: 14px;
}

.raise_second_installment {
    width: 80%;
    margin: auto;
    text-align: end;
    margin-bottom: 10px;
}

.raise_second_installment button {
    width: auto;
}

.second_installment_button button {
    width: 105px;
    font-size: 12px;
}

.raise_grievance_btn button {
    width: auto;
}

.table_heading,
.table_data,
.table_content {
    border: 1px solid #eab7f5;
    padding: 4px 0px;
    text-align: center;
    border-radius: 5px;
    /* position: relative; */
}

.table_heading {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
    margin-top: 15px !important;
}

.table_data,
.table_content {
    box-shadow: none;
    margin-top: 15px;
}

.table_heading .text_align,
.table_heading .align,
.table_data .text_align,
.table_data .align {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgb(161, 161, 161);
}

.table_heading .align,
.table_data .align {
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.action i,
.userAction i {
    color: #790a81;
    cursor: pointer;
}

.form_section {
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.form_title {
    text-align: center;
    margin-bottom: 20px;
}setFilteredData

.form,
.add_user_form,
.add_notification_form,
.registration_form {
    width: 40%;
    margin: auto;
    background-color: var(--whiteThemeBgColor);
    border-radius: 0.5rem;
    padding: 2%;
}

.registration_form {
    width: 50%;
}

.add_user_form,
.add_notification_form {
    width: 70%;
    margin: auto;
}

.searchWrapper {
    background-color: #fff;
}

.multiSelectContainer li {
    width: 100%;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
}
.multiSelectContainer
input[type=checkbox], input[type=radio] {
    margin: 3px 0 0;
}
.table_section {
    width: 100%;
    height: 63vh;
    overflow: auto;
}

.table_main {
    width: 1250px;
    overflow: auto;
}

.table_main .table_heading,
.table_main .table_content {
    display: flex;
}

.sr_no,
.userAction {
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgb(161, 161, 161);
}

.sr_no {
    width: 130px;
}

.userAction {
    border: none;
}

.user_id {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgb(161, 161, 161);
}

.full_name {
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgb(161, 161, 161);
}

.department,
.designation,
.mobile_number {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgb(161, 161, 161);
}

.zone,
.district,
.division,
.state {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgb(161, 161, 161);
}

/*--navbar----*/
.user_navbar,
.back_office_navbar {
    position: sticky;
    height: 5vh;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    font-weight: 400;
    background-image: radial-gradient(#ad5389, #3c1053);
    padding: 0;
    margin-bottom: 0;
    z-index: 1;
}

.user_navbar a,
.back_office_navbar a {
    text-decoration: none;
}

.user_navbar li span,
.back_office_navbar li span {
    color: #fff !important;
    font-weight: 400;
    cursor: pointer;
}

.user_navbar li,
.back_office_navbar li {
    position: relative;
}

.user_navbar li .badge,
.back_office_navbar li .badge {
    position: absolute;
    top: 5%;
    right: -70%;
    background-color: yellow;
    padding: 1px 8px;
    border-radius: 8px;
    display: grid;
    place-items: center;
}

.back_office_navbar li .badge {
    right: -34%;
}

.user_navbar li .badge span,
.back_office_navbar li .badge span {
    color: #000 !important;
    border-bottom: none !important;
}

.user_navbar li {
    margin: 0px 38px;
}

.back_office_navbar li {
    margin: 0px 15px;
}

.user_navbar .active li span,
.back_office_navbar .active li span {
    border-bottom: 2px solid #ad5389;
}

.dashboard {
    width: 100%;
    /* height: 70vh; */
    height: auto;
}

.section {
    width: 100%;
    height: 70vh;
    overflow-y: auto;
    padding-bottom: 20px;
}

/*----css for user portal*/
.registration_form {
    height: 72vh;
    overflow-y: auto;
    padding: 1% 2%;
}

.registration_form::-webkit-scrollbar {
    width: 0;
}

.registration_form .address {
    min-height: 75px;
}

.login_section a,
.form_section a {
    text-decoration: none;
}

.notification_card {
    width: 85%;
    margin: auto !important;
    border: 1px solid #eab7f5;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 15px !important;
}

.notification_card .subject {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 3px;
    text-transform: capitalize;
}

.notification_card_button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification_date {
    display: flex;
    margin-top: 5px;
}

.notification_date span,
.notification_date p {
    font-size: 14px;
}

.notification_date p {
    margin: 0 5px;
}

.notification_date span:nth-child(3) {
    margin-left: 5px;
}

.myapplication_main {
    width: 100%;
}

.myapplication_main .table_heading,
.myapplication_main .table_data {
    width: 80%;
    margin: auto;
}

.myapplication_data_main {
    width: 100%;
    position: relative;
    margin-top: 15px;
    overflow-x: hidden;
}

.second_installment_button {
    position: absolute;
    top: 50%;
    left: 94.3%;
    transform: translate(-50%, -50%);
}

.chatbot_main {
    width: 80%;
    margin: auto;
    height: 68vh;
    background-color: #f6f9fd;
    border-radius: 4px;
    position: relative;
}

.chatbot_header {
    width: 100%;
    position: relative;
    padding: 3px;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: var(--whiteThemeBgColor);
    margin-bottom: 3px;
    font-weight: 500;
}

.chatbot_back_btn {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.message_section {
    width: 100%;
    height: 49vh;
    padding: 0 2%;
    overflow-y: auto;
}

.message_section::-webkit-scrollbar {
    width: 0;
}

.back_office_message,
.user_message,
.attached_dacument_section {
    margin-bottom: 10px !important;
}

.reply_section {
    width: 100%;
    height: 12.5vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.message_box_main,
.attached_dacument_section {
    width: 60%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    padding: 4px 10px;
}

.attached_dacument_section {
    width: 30%;
    padding: 0;
}

.attached_dacument_section .attached_file {
    width: 100%;
    height: 150px;
    padding: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #c4c4c4;
    border-bottom-right-radius: 0px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.attached_file .fa {
    font-size: 5rem;
    color: red;
    cursor: pointer;
}

.attached_dacument_section .message {
    padding: 5px;
}

.attached_dacument_section .detail {
    padding: 3px;
}

.message {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.message span {
    font-size: 14px;
}

.detail {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 2px;
}

.reply_by,
.date,
.time {
    font-size: 12px;
}

.date {
    margin: 0 10px;
    font-size: 11px;
}

.grievance_attach_file,
.send_btn {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.type_message {
    width: 80%;
}

.type_message span {
    font-size: 12px;
}

.grievance_attach_file i,
.send_btn i {
    cursor: pointer;
}

/*-----css for new application form---*/
.form_main .form_heading {
    padding: 10px 0;
    text-align: center;
}

.application_form {
    width: 85%;
    margin: auto;
    padding: 1% 2%;
    background-color: var(--whiteThemeBgColor);
    box-shadow: var(--boxShadow);
    border-radius: 4px;
}

.application_form .row {
    display: flex;
    align-items: center;
}

.application_form .file_preview {
    display: flex;
    align-items: center;
}

.application_form .file_preview i {
    font-size: 18px;
    cursor: pointer;
    color: #3c1053;
}

.newApplication_modal_main {
    width: 100%;
    height: 70vh;
    background-color: #fff;
    position: relative;
}

.newApplication_modal {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    padding: 2%;
    border-radius: 4px;
}

/*-------css for Back office------------------*/
.back_office_section {
    width: 100%;
    padding: 1% 3% 0 3%;
}

.back_office_table_main {
    width: 100%;
    height: 50vh;
    overflow: auto;
}

.back_office_table {
    width: 1268px;
    /* overflow: auto;
    position: relative; */
}

.back_office_notification_table {
    width: 100%;
}

.back_office_table_heading,
.back_office_table_data {
    border: 1px solid #eab7f5;
    padding: 4px 0px;
    text-align: center;
    border-radius: 5px;
    display: flex;
}

.back_office_table_heading {
    position: sticky;
    top: 0;
}

.back_office_table_data {
    margin-top: 15px;
}

.alert .description h6 {
    text-align: left !important;
}

.back_office_sr_no,
.user_name,
.application_id,
.application_type,
.back_office_status,
.back_office_comment,
.back_office_date,
.back_office_action {
    display: grid;
    place-items: center;
    border-right: 1px solid rgb(161, 161, 161);
}

.back_office_sr_no {
    width: 10%;
}

.user_name {
    width: 20%;
}

.application_id {
    width: 11%;
}

.application_type,
.back_office_status {
    width: 15%;
}

.back_office_comment {
    width: 20%;
}

.final_selected .back_office_comment {
    width: 18%;
}

.back_office_date {
    width: 9%;
    border-right: none;
}

.state_selected .user_name,
.final_selected .user_name {
    width: 15%;
}

.state_selected .application_id {
    width: 12%;
}

.final_selected .application_id {
    width: 10%;
}

.state_selected .back_office_status,
.final_selected .back_office_status {
    width: 10%;
}

.back_office_action {
    width: 13%;
    border-right: none;
}

.final_selected .back_office_action {
    width: 10%;
    border-right: 1px solid rgb(161, 161, 161);
}

.final_selected .view_review {
    width: 7%;
    border-right: none;
    display: grid;
    place-items: center;
}

.state_selected .back_office_date,
.final_selected .back_office_date {
    border-right: 1px solid rgb(161, 161, 161);
}

.central_acknowledge,
.add_review {
    font-size: 12px;
    border-radius: 5px;
    padding: 2px 5px;
    border: var(--buttonBgColor);
    background-color: var(--buttonBgColor);
    color: #fff;
}

.add_review {
    padding: 2px 10px;
}

.back_office_button_group {
    display: flex;
    justify-content: end;
}

.back_office_button_group button {
    padding: 1px 8px;
    border: 1px solid var(--buttonBgColor);
    background-color: var(--buttonBgColor);
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
}

.notification_review_button {
    position: absolute;
    top: 50%;
    left: 98.5%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.view_review_section {
    width: 100%;
    height: 70vh;
    overflow-y: auto;
    padding-top: 15px;
    position: relative;
}

.view_review_section .table_heading {
    position: sticky;
    top: 0;
}

.view_review_section .table_heading,
.view_review_section .table_data {
    width: 75%;
    margin: auto;
}

.view_review_section .table_data_main {
    position: relative;
    margin-top: 15px;
}

.table_data_main .review_button {
    position: absolute;
    top: 50%;
    left: 91%;
    transform: translate(-50%, -50%);
    display: flex;
}

.table_data_main .review_button button {
    border-radius: 5px;
    padding: 1px 5px;
    color: #fff;
    background-color: var(--buttonBgColor);
    border: 1px solid var(--buttonBgColor);
    font-size: 14px;
}

.view_review_section i {
    color: var(--buttonBgColor);
    cursor: pointer;
}

.view_review_section .back_button {
    text-align: end;
    margin-right: 13%;
    margin-bottom: 10px;
}

.view_review_section .back_button i {
    font-size: 19px;
}

.back_office_user_action {
    width: 85%;
    margin: auto;
    padding: 20px 0;
}

.back_office_user_action .row {
    width: 100%;
}

.approve_certificates .preview_button {
    width: 100%;
    display: grid;
    place-items: center;
}

.swal-icon:first-child {
    text-align: center;
    margin: 10px auto;
}

.swal-footer {
    text-align: center;
    margin-top: 5px;
}

/*-----print table css----*/
.print_table_section {
    padding: 2% 3%;
    position: relative;
}

.file_number {
    text-align: center;
}

.annexure_no {
    text-align: end;
    margin: 15px 0;
}

.annexure_header {
    text-align: center;
}

.annexure_table {
    width: 100%;
    margin-top: 30px;
}

.annexure_table th {
    text-align: center;
}

.annexure_table th,
.annexure_table td {
    border: 1px solid #000;
}

.annexure_table th,
.annexure_table td {
    padding: 3px;
}

.annexure_table th {
    font-size: 14px;
}

.annexure_table td {
    font-size: 13px;
    font-weight: 600;
}

.annexure_table tbody tr td:nth-child(1) {
    width: 5%;
    text-align: center;
}

.annexure_table tbody tr td:nth-child(2),
.annexure_table tbody tr td:nth-child(3) {
    text-align: left;
    padding-left: 10px;
    width: 65%;
}

.annexure_table tbody tr td:nth-child(3) {
    width: 30%;
}

.print_icon {
    position: absolute;
    top: 30px;
    right: 40px;
}

.print_icon .fa {
    font-size: 20px;
    cursor: pointer;
}

.hidden-print {
    display: block !important;
}

.Authorization_detail {
    text-align: right;
}

.table_text_align {
    text-align: left;
    padding-left: 10px;
}

.second_installment_table {
    text-align: center;
}

.document_list {
    width: 100%;
}

.second_installment_table th,
.second_installment_table tr,
.second_installment_table td,
.document_list th,
.document_list tr,
.document_list td {
    border: 1px solid black;
}

.second_installment_table th {
    font-size: 14px;
    padding: 3px;
}

.second_installment_table td {
    font-size: 13px;
    font-weight: 600;
    padding: 3px;
}

.document_list th {
    font-size: 14px;
    padding: 6px;
}

.document_list td {
    font-size: 13px;
    font-weight: 600;
    padding: 6px;
}

.bg-dark {
    background-image: radial-gradient(#ad5389, #3c1053) !important;
}


/* css for pagination */
.pagination_main {
    overflow-x: auto;
    /* position: absolute;
    bottom: -3%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    place-items: center;
    padding: 5px 0;
    background-color: #fff; */
}

.pagination_main::-webkit-scrollbar {
    width: 0;
}

.pagination {
    margin-bottom: 0;
    display: flex;
    border-radius: 10px;


}

.pagination i {
    color: #790a81;
}

.pagination li {
    padding: 0px 0px 0px 0px;
    color: #fff !important;
    /* border: 0.5px solid #a020f0; */
    margin: 0 0px;
    border-radius: 3px;
    font-size: 11px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    transition: .5s;

}

.pagination .previous,
.pagination .next {
    border: none;
    cursor: pointer !important;

}

.pagination li:hover {
    background-color: #a020f0;
    color: #fff ;
    cursor: pointer 

}

.pagination li:hover a {
    color: #fff;

}

.pagination li a {
    color: #a020f0 !important;
    cursor: pointer;
}

.pagination>li>a {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: 3px;
    line-height: 1.42857143;
    color: #e41494 !important;
    text-decoration: none;
    /* background-color:  #790a81 !important; */
    border: 1px solid #ddd;
 
}

.pagination .active a {
    /* color: #fff !important; */
    border-radius: 50%;
    margin: 0 5px;
    background-color: #790a81 !important;
    border-color: #790a81 !important;
    cursor: pointer !important;
    color: #e41494 !important;
}

.pagination_count {

    margin: 0 10px;
    background-color: #790a81;
    border-color: #790a81;
}

.pagination> a,
.pagination>.disabled>a:hover {
    /* color: #777; */
    cursor: pointer !important;
    /* background-color: #fff;
    border-color: #ddd; */
}

/* search css */
.search {
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}

.search-input {
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(56, 56, 56, 0.16);
    border: none;
    width: 25rem;
}

.search-input:focus {
    outline: none;
}

.search-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #A020F0;
}

.btn_search_section {
    background-image: linear-gradient(to right, #a020f0, #501078);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 3px 10px;
}

.add_button {
    text-align: left;
    margin-bottom: 0;
}

/* css for search header */
.top_search_header {
    display: flex;
    align-items: center;
    gap: 30px;
}

.top_search_header_backoffice {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 85rem; */
}

.header_wrapper,
.my_application_header_wrapper,
.alert_header_wrapper {
    position: sticky !important;
    top: 0 !important;
    width: 85% !important;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 15px;
}

.my_application_header_wrapper {
    width: 80% !important;
}
.alert_header_wrapper {
    width: 100% !important;
}
.pagination_class,
.header_wrapper .user_portal_pagination {
    text-align: right !important;
}

.user_portal_pagination .pagination {
    margin: 0;
}


.password_icon i{
    position: absolute;
    top: 63%;
    left: 62%;
    transform: translate(-50%, -50%);
}
.password_icon_div i{
    position: absolute;
    top: 35%;
    left: 87%;
    transform: translate(-50%, -50%);
}
.password_icon_div_sec i{
    position: absolute;
    top: 50%;
    left: 87%;
    transform: translate(-50%, -50%);
}

.form{
    padding:20px;
    width:40%;
}